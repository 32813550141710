import React, { useState, useEffect } from "react";
import Thumbnail from "./Thumbnail";
import { motion, useAnimation, AnimatePresence } from "framer-motion";
import Lightbox from "./Lightbox";

async function fetchMedia(setLightboxImage) {
  const mediaListing = await (
    await fetch("https://media.nephrited.com/portfolio/gallery/thumbnails/")
  ).text();
  const fileNameArray = [
    ...mediaListing.matchAll(
      new RegExp(/(?:<a href=")((?:.+)(?:\.png|\.jpeg|\.jpg))(?:">)/g)
    ),
  ];
  const mediaArray = [];

  if (fileNameArray != null) {
    fileNameArray.forEach((match) => {
      mediaArray.push(match[1]);
    });
  }

  mediaArray.sort(() => Math.random() - 0.5);

  return generateThumbnails(mediaArray, setLightboxImage);
}

function generateThumbnails(mediaArray, setLightboxImage) {
  const thumbnails = mediaArray.map((mediaUri, index) => {
    return (
      <Thumbnail
        imageUri={mediaUri}
        placeholder={mediaUri ? false : true}
        key={index}
        imageFunction={setLightboxImage}
      ></Thumbnail>
    );
  });
  return thumbnails;
}

function Gallery(props) {
  const controls = useAnimation();
  const [thumbnails, setThumbnails] = useState(null);
  const [lightboxImage, setLightboxImage] = useState(null);

  const variants = {
    hidden: {},
    visible: { transition: { staggerChildren: 0.2 } },
  };

  async function initialThumbnails() {
    const mediaArray = new Array(10).fill({});
    setThumbnails(generateThumbnails(mediaArray, setLightboxImage));
    setThumbnails(await fetchMedia(setLightboxImage));
  }

  useEffect(() => {
    initialThumbnails();
  }, []);

  useEffect(() => {
    controls.start("visible");
  }, [controls, thumbnails]);

  useEffect(() => {}, [lightboxImage]);

  return (
    <span>
      <AnimatePresence>
        {lightboxImage && (
          <Lightbox
            imageUri={lightboxImage}
            clearImageFunction={setLightboxImage}
          ></Lightbox>
        )}
      </AnimatePresence>
      <motion.div
        className="Gallery__Container"
        variants={variants}
        initial="hidden"
        animate={controls}
      >
        {thumbnails}
      </motion.div>
    </span>
  );
}

export default Gallery;
