import React from "react";
import "./App.css";
import HeaderBar from "./HeaderBar";
import { HashRouter, Route } from "react-router-dom";

import Gallery from "./Gallery";
import Home from "./Home";
import Socials from "./Socials";
import About from "./About";
import Contact from "./Contact";
import Footer from "./Footer";

function App() {
  return (
    <HashRouter>
      <div className="App">
        <HeaderBar></HeaderBar>

        <Route exact path="/" component={Home} />
        <Route path="/gallery" component={Gallery} />
        <Route path="/about" component={About} />
        <Route path="/contact" component={Contact} />
        <Route path="/socials" component={Socials} />

        <Footer></Footer>
      </div>
    </HashRouter>
  );
}

export default App;
