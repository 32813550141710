import React from "react";

function About(props) {
  const year = new Date().getFullYear();
  const drawingSince = year - 2004;
  const programmingSince = year - 2014;

  return (
    <div className="About">
      <h1 className="About__Title">
        I'm <em>Nephi</em>, and it's <em>nice to meet you!</em>
      </h1>
      <div className="About__Content">
        <p>I'm a digital artist, programmer, designer and occasional gamer.</p>
        <p>
          I've been drawing on and off for {drawingSince} years, and I hope to
          make it a career someday!
        </p>
        <p>
          I currently use a Wacom Cintiq Pro 16 hooked up a desktop, and I
          generally work in Photoshop CC.
        </p>
        <p>I have a particular fondness for drawing shortstack characters.</p>
        <p>
          I've got a BSc in Computer Science, and a masters in Information
          Systems, which I've used professionally working as a software
          developer for the last {programmingSince} years, mostly in web
          development. I'm particularly good with Javascript and websites.
          Despite my qualifications, though, art is my true love...!
        </p>
        <p>
          I used to play TF2 semi-professionally, but these days I only really
          play League of Legends and World of Warcraft. I play a Gnome Priest in
          WoW, as there are no other good options. I'll fight you on this.
        </p>
      </div>
      <h1 className="About__FAQ">
        <em>F.A.Q.</em>
      </h1>
      <h2>Where are you from?</h2>
      <p>
        I'm British Irish, although I hold an English passport, have an English
        accent, and I've lived in England, Germany and the USA.
      </p>
      <h2>How old are you?</h2>
      <p>
        I get asked this a lot and I genuninely can never remember. I could work
        it out but I don't want to.
      </p>
      <h2>Professor Oak: "Are you a boy or a girl?"</h2>
      <p>
        I'm a chick. I had a phase when I was young where I thought I might be a
        guy which was confusing for all involved though.
      </p>
      <h2>Why do you love shortstacks so much?</h2>
      <p>
        When I was a teenager I played a game called Twilight Princess. Twilight
        Princess has a character called{" "}
        <a href="https://www.google.com/search?tbm=isch&as_q=midna">
          "Midna..."
        </a>
      </p>
      <h2>What's your favourite video game?</h2>
      <p>Vanilla TF2 will always have a special place in my heart.</p>
      <h2>I've seen some photos of you and...what happened?</h2>
      <p>I had a breast reduction is what.</p>
      <h2>How tall are you? You look tall.</h2>
      <p>Too tall.</p>
      <h2>Are you human?</h2>
      <p>
        Oh yes,{" "}
        <a href="https://tvtropes.org/pmwiki/pmwiki.php/Main/Glamour">
          totally.
        </a>
      </p>
      <h2>Why "Nephi"?</h2>
      <p>
        Nephi (pronounced "Neh-fee", not "knee-f-eye") is short for Nephrited,
        which is a pun on my real name. If you can work the pun out, or if you
        know my real name, use Nephi anyway. I prefer it.
      </p>
    </div>
  );
}

export default About;
