import React from "react";
import twitterLogo from "./res/social-twitter.png";
import discordLogo from "./res/social-discord.png";
import newgroundsLogo from "./res/social-ng.png";
import twitchLogo from "./res/social-twitch.png";

function Socials(props) {
  return (
    <div className="Socials">
      <a href="https://twitter.com/nephrited">
        <img src={twitterLogo}></img>
      </a>
      <a href="https://nephi.co/discord">
        <img src={discordLogo}></img>
      </a>
      <a href="https://nephrited.newgrounds.com/">
        <img src={newgroundsLogo}></img>
      </a>
      <a href="https://twitch.com/nephrited">
        <img src={twitchLogo}></img>
      </a>
    </div>
  );
}

export default Socials;
