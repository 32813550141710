import React from "react";
import HeaderButton from "./HeaderButton";

import TwitterIcon from "./res/Twitter-Logo.png";
import DiscordIcon from "./res/Discord-Logo.png";

function HeaderBar(props) {
  return (
    <header>
      <nav>
        <HeaderButton label="Home" to="/"></HeaderButton>
        <HeaderButton label="Gallery" to="/gallery"></HeaderButton>
        <HeaderButton label="About" to="/about"></HeaderButton>
        <HeaderButton label="Contact" to="/socials"></HeaderButton>
        <span className="HeaderBar__header__nav_IconContainer">
          <a href="https://nephi.co/discord">
            <img
              className="HeaderBar__header__nav__IconContainer_icon socialIcon"
              src={DiscordIcon}
              alt="Discord Server"
            ></img>
          </a>
          <a href="https://twitter.com/nephrited">
            <img
              className="HeaderBar__header__nav__IconContainer_icon socialIcon"
              src={TwitterIcon}
              alt="Twitter"
            ></img>
          </a>
        </span>
      </nav>
    </header>
  );
}

export default HeaderBar;
