import React, { useState } from "react";
import { motion } from "framer-motion";

const variants = {
  hidden: { opacity: 0, y: -50 },
  visible: { opacity: 1, y: 0 },
};

function Thumbnail(props) {
  const [loaded, setLoaded] = useState(false);

  function handleClick() {
    props.imageFunction(props.imageUri);
  }

  return (
    <motion.div
      className="Thumbnail_container"
      variants={variants}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
      onClick={handleClick}
    >
      <div className="Thumbnail__container_imageContainer">
        {loaded && !props.placeholder ? null : (
          <div className="Thumbnail__container__imageContainer_spinner">
            <div className="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        )}
        <img
          className="Thumbnail__container__imageContainer_image"
          src={`https://media.nephrited.com/portfolio/gallery/thumbnails/${props.imageUri}`}
          alt=""
          style={loaded && !props.placeholder ? {} : { display: "none" }}
          onLoad={() => setLoaded({ loaded: true })}
        />
      </div>
    </motion.div>
  );
}

export default Thumbnail;
