import React from "react";

function Home(props) {
  return (
    <span>
      <div className="Home_divContainer">
        <p>
          I'm <em>Nephi</em>
        </p>
        <p>
          a <em>digital artist</em>
        </p>
        <p>
          and <em>web developer</em>
        </p>
      </div>
      <div className="Home_underConstruction">
        This site is a work in progress, so if you find any bugs in the layout,
        <a href="https://twitter.com/nephrited"> give me a shout.</a>
      </div>
    </span>
  );
}

export default Home;
