import React from "react";
import { NavLink } from "react-router-dom";

function HeaderButton(props) {
  return (
    <NavLink
      exact
      to={props.to}
      className="HeaderButton__NavLink"
      activeClassName="HeaderButton__NavLink_selected"
    >
      {props.label}
    </NavLink>
  );
}

export default HeaderButton;
