import React from "react";

import TwitterIcon from "./res/Twitter-Logo.png";
import DiscordIcon from "./res/Discord-Logo.png";

function Footer(props) {
  return (
    <div className="Footer_container">
      <span className="Footer__div_IconContainer">
        <a href="https://nephi.co/discord">
          <img
            className="Footer__div_IconContainer_icon socialIcon"
            src={DiscordIcon}
            alt="Discord Server"
          ></img>
        </a>
        <a href="https://twitter.com/nephrited">
          <img
            className="Footer__div_IconContainer_icon socialIcon"
            src={TwitterIcon}
            alt="Twitter"
          ></img>
        </a>
      </span>
    </div>
  );
}

export default Footer;
