import React from "react";
import { motion } from "framer-motion";

function Lightbox(props) {
  function handleShadeClick() {
    props.clearImageFunction(null);
  }

  function handleImageClick(e) {
    e.stopPropagation();
  }

  return (
    <motion.div
      initial={{ top: "-100vh" }}
      animate={{ top: "0px" }}
      exit={{ top: "-100vh" }}
      onClick={handleShadeClick}
      className="Lightbox__Shade"
    >
      <div className="Lightbox__Container">
        <img
          src={`https://media.nephrited.com/portfolio/gallery/${props.imageUri}`}
          className="Lightbox__Container__Image"
          alt=""
          onClick={handleImageClick}
        ></img>
        <span
          onClick={handleShadeClick}
          className="Lightbox__Container__Close"
        ></span>
      </div>
    </motion.div>
  );
}

export default Lightbox;
